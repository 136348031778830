import { useSelector } from "react-redux";
import { useContentContext } from "../../../contexts/ContentContext";
import { Helmet } from "react-helmet";
import { getSiteSetting } from "../../../selectors";
import { useLocation } from "react-router";

const useSEO = () => {
  const pageData = useContentContext();
  const formattedSEO = pageData?.formattedSEO;
  const siteSettings = useSelector(getSiteSetting);
  const location = useLocation();

  const SEO = () => {
    return (
      <>
        <Helmet>
          {formattedSEO?.title && <title>{formattedSEO.title}</title>}
          {formattedSEO?.alternates?.canonical && <link rel="canonical" href={formattedSEO.alternates.canonical} />}
          {siteSettings?.link?.map((link, index) => {
            if (!(link.regex || /(.*?)/).test(location.pathname)) return null;
            return (
              <link key={index} {...link.attributes}>
                {link.innerText}
              </link>
            );
          })}
          {siteSettings?.script?.map((script, index) => {
            if (!(script.regex || /(.*?)/).test(location.pathname)) return null;
            return (
              <script key={index} {...script.attributes}>
                {script.innerText}
              </script>
            );
          })}
        </Helmet>
        {formattedSEO?.meta?.map((meta, index) => (
          <Helmet key={index}>
            <meta name={meta.name} content={meta.content} />
          </Helmet>
        ))}
      </>
    );
  };

  return { SEO };
};

export { useSEO };
