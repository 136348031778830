import { Cart, OrderItem } from "../../interface/Cart";
import { CartCheckOutProps } from "../../modules/Cart/Controls/CartCheckOut";
import { CartItemListProps } from "../../modules/Cart/Controls/CartItemList";

export interface QuoteCheckOutModel {
  quoteReadyItems: OrderItem[];
  orderID: string;
}

class QuoteModel {
  isRemovingItem: boolean = false;
  isEmptyCart: boolean = false;
  components: {
    quoteOrderItemList?: Omit<CartItemListProps, "onRemoveItem" | "onUpdateQty">;
    cartCheckOut?: CartCheckOutProps;
    quoteCheckOut?: QuoteCheckOutModel;
  } = {};

  constructor({ cart, isRemovingItem, isAllItems }: { cart: Cart; isRemovingItem: boolean; isAllItems: boolean }) {
    const { orderItems = [], orderID } = cart;

    this.isRemovingItem = isRemovingItem;
    const cartAndQuoteItems = orderItems.filter((item) => item.parentOrderItemID === "");
    const quoteReadyItems = isAllItems ? cartAndQuoteItems : cartAndQuoteItems.filter((item) => item.quoteOnly);

    if (quoteReadyItems.length) {
      this.components.quoteOrderItemList = {
        title: `The following items will be sent as quote`,
        cartItems: quoteReadyItems,
        orderItems: orderItems,
        isFetching: cart.isFetching,
        isRemovingItem: isRemovingItem,
      };
      this.components.quoteCheckOut = { orderID, quoteReadyItems };
    } else {
      this.isEmptyCart = true;
    }
  }
}

export default QuoteModel;
