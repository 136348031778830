import { OrderItem } from "../../../interface/Cart";
import { useElementContext } from "../../../contexts";

export interface CartItemListProps {
  title: string;
  isFetching: boolean;
  cartItems: (OrderItem & { errorMsg?: string })[];
  orderItems: OrderItem[];
  isRemovingItem: boolean;
  onRemoveItem: (id: string) => void;
  onUpdateQty: (orderItemID: string, quantity: number) => void;
}

const CartItemList = ({
  title,
  isFetching,
  cartItems,
  isRemovingItem,
  orderItems,
  onRemoveItem,
  onUpdateQty,
}: CartItemListProps) => {
  const { CartModule } = useElementContext();
  return (
    <div className="card mb-4 border-0">
      <div className="card-header bg-white">
        <h3 className="pt-0 mb-0">{title}</h3>
      </div>
      <div className="card-body p-0">
        {cartItems.map((orderItem, key) => {
          const childBundleItems = orderItems?.filter((item) => item?.parentOrderItemID === orderItem.orderItemID);
          return (
            <CartModule.CoreComponents.CartLineItem
              isFetching={isFetching}
              key={`${orderItem.orderItemID}-${key}`}
              orderItem={orderItem}
              childBundleItems={childBundleItems}
              isRemovingItem={isRemovingItem}
              onRemoveItem={onRemoveItem}
              onUpdateQty={onUpdateQty}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CartItemList;
