import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getBrandRoute, getProductRoute } from "../../../selectors";

import { ProductCardConfiguration, SkuCardConfiguration } from "../../ProductListing/productListing.moduleModels";
import { SearchSku, SearchProduct } from "../../../interface/SearchProduct";
import { useElementContext } from "../../../contexts";

export interface SkuCardProps extends SearchSku {
  cardConfiguration: SkuCardConfiguration | ProductCardConfiguration;
  cardType?: string;
}

export interface ProductCardProps extends SearchProduct {
  cardConfiguration: SkuCardConfiguration | ProductCardConfiguration;
  cardType?: string;
}

const SkuCard = (props: SkuCardProps) => {
  const { ProductModule, ProductCardModule } = useElementContext();
  const {
    cardConfiguration,
    skuName,
    product_productCode: productCode,
    salePrice,
    product_urlTitle: urlTitle,
    product_brand_brandName: brandName,
    product_brand_urlTitle: brandUrlTitle,
    listPrice,
    productClearance,
    skuID = "",
    skuCode,
    settings,
    skuDescription,
    cardType = "Basic",
  } = props;

  const { t } = useTranslation();

  const brand = useSelector(getBrandRoute);

  const product = useSelector(getProductRoute);
  const productLink = `/${product}/${urlTitle}` + (skuID.length ? `?skuid=${skuID}` : "");

  if (!cardConfiguration) {
    console.log("Missing Configuration");
    return null;
  }
  return (
    <>
      {cardType === "Basic" && (
        <div className="card skuCard p-3 pt-2 h-100">
          {productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
          {cardConfiguration.enableFavorites && <ProductModule.CoreComponents.HeartButton skuID={skuID} />}
          <Link to={productLink}>
            <ProductCardModule.CoreControl.ProductCardImage product={props} />
          </Link>
          <div className="card-body">
            {cardConfiguration?.showBrand && (
              <Link to={`/${brand}/${brandUrlTitle}`} className="text-capitalize mb-3" style={{ fontSize: 12 }}>
                {brandName}
              </Link>
            )}
            <h2>
              <Link to={productLink} className="product-name d-inline-block w-100">
                {skuName}
              </Link>
            </h2>
            {cardConfiguration.showProductCode && productCode && <div className="product-brand">{productCode}</div>}
            {cardConfiguration.showSkuCode && skuCode && <div className="product-brand">{skuCode}</div>}
            {cardConfiguration?.showPrice && (
              <ProductModule.CoreComponents.ProductPrice
                salePrice={salePrice}
                listPrice={listPrice}
                showPriceForUserType={settings?.skuShowPriceForUserType}
              />
            )}
          </div>
          <ProductCardModule.CoreComponents.ProductCardActions
            type="listing"
            sku={props}
            cardConfiguration={cardConfiguration}
          />
        </div>
      )}

      {cardType === "Image Fill" && (
        <div className="card skuCard h-100">
          {productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
          {cardConfiguration.enableFavorites && <ProductModule.CoreComponents.HeartButton skuID={skuID} />}
          <Link to={productLink} className="position-relative">
            <div
              className="position-absolute top-0 h-auto w-100 text-center"
              style={{
                backgroundColor: "rgba(255,255,255,0.5)",
                color: "black",
              }}
            >
              <p className="fw-bold px-2">{skuName}</p>
            </div>
            <ProductCardModule.CoreControl.ProductCardImage product={props} />
            <div
              className="position-absolute bottom-0 h-auto w-100 text-center"
              style={{
                backgroundColor: "rgba(255,255,255,0.5)",
                color: "black",
              }}
            >
              <p className="fw-bold px-2 d-flex justify-content-center align-items-center">
                <ProductModule.CoreComponents.ProductPrice
                  salePrice={salePrice}
                  listPrice={listPrice}
                  showPriceForUserType={settings?.skuShowPriceForUserType}
                />
              </p>
            </div>
          </Link>
        </div>
      )}

      {cardType === "Full width Description Card" && (
        <div className="card skuCard h-100 d-flex flex-row col-12 flex-wrap justify-content-center">
          {productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
          {cardConfiguration.enableFavorites && <ProductModule.CoreComponents.HeartButton skuID={skuID} />}
          <Link
            to={productLink}
            style={{ maxHeight: "250px", maxWidth: "250px" }}
            className="col-12 col-lg-3 justify-content-center"
          >
            <ProductCardModule.CoreControl.ProductCardImage product={props} />
          </Link>
          <div className="card-body col-lg-9 col-12">
            <h2>
              <Link to={productLink} className="product-name d-inline-block w-100">
                <b>{skuName}</b>
              </Link>
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: skuDescription,
              }}
            />
            <span>
              <Link to={productLink} className="product-name d-inline-block w-100 mt-2">
                <b>More about {skuName + " >"} </b>
              </Link>
            </span>
          </div>
        </div>
      )}

      {cardType === "Third width Description Card" && (
        <div className="card skuCard third-desc-skucard h-100 d-flex flex-row col-lg-4 col-12 flex-wrap justify-content-center align-content-start">
          {productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
          {cardConfiguration.enableFavorites && <ProductModule.CoreComponents.HeartButton skuID={skuID} />}
          <Link
            to={productLink}
            style={{ maxHeight: "250px", maxWidth: "250px" }}
            className="col-12 justify-content-center"
          >
            <ProductCardModule.CoreControl.ProductCardImage product={props} />
          </Link>
          <div className="card-body col-12">
            <h2>
              <Link to={productLink} className="product-name d-inline-block w-100">
                <b>{skuName}</b>
              </Link>
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: skuDescription,
              }}
              style={{ maxHeight: "140px", overflowY: "scroll" }}
            />
            <span>
              <Link to={productLink} className="product-name d-inline-block w-100 mt-2">
                <b>More about {skuName + " >"} </b>
              </Link>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const AuthenticateRequiredMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="alert alert-warning fs-6" role="alert">
      {t("frontend.product.loginToAdd")}
      <br />
      <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
    </div>
  );
};

const ProductCard = (props: ProductCardProps) => {
  const { ProductModule, ProductCardModule } = useElementContext();
  const {
    cardConfiguration,
    productName,
    salePrice,
    urlTitle,
    brand_brandName: brandName,
    brand_urlTitle: brandUrlTitle,
    listPrice,
    productClearance,
    defaultSku_skuID: skuID,
    defaultSku_skuCode: skuCode,
    settings,
    productConfigurator,
  } = props;
  const defaultSku = props.skus.find((sku) => sku.skuID === props.defaultSku_skuID) || props.skus[0];
  const { t } = useTranslation();

  const brand = useSelector(getBrandRoute);
  const product = useSelector(getProductRoute);
  const isMultiSkus = props.skus?.length > 1;
  const productLink = `/${product}/${urlTitle}` + (skuID.length ? `?skuid=${skuID}` : "");
  if (!cardConfiguration) {
    console.log("Missing Configuration");
    return null;
  }

  return (
    <div className="card productCard p-3 pt-2 h-100">
      {productClearance === true && <span className="badge">{t("frontend.core.special")}</span>}
      {cardConfiguration.enableFavorites && <ProductModule.CoreComponents.HeartButton skuID={skuID} />}
      <Link to={productLink} className="border">
        {defaultSku && <ProductCardModule.CoreControl.ProductCardImage product={defaultSku} />}
      </Link>
      <div className="card-body py-2 px-0">
        {cardConfiguration?.showBrand && (
          <Link to={`/${brand}/${brandUrlTitle}`} className="text-capitalize mb-3" style={{ fontSize: 12 }}>
            {brandName}
          </Link>
        )}
        <h2 className="pt-2">
          <Link to={productLink} className="product-name d-inline-block w-100">
            {productName}
          </Link>
        </h2>
        {/* {cardConfiguration.showProductCode && productCode && <div className="product-brand">{productCode}</div>} */}
        {cardConfiguration.showSkuCode && skuCode && <div className="product-brand">{skuCode}</div>}
        {cardConfiguration?.showPrice && !isMultiSkus && (
          <ProductModule.CoreComponents.ProductPrice
            salePrice={salePrice}
            listPrice={listPrice}
            showPriceForUserType={settings?.skuShowPriceForUserType}
          />
        )}
      </div>
      {defaultSku && (
        <ProductCardModule.CoreComponents.ProductCardActions
          type="listing"
          sku={defaultSku}
          cardConfiguration={cardConfiguration}
          productConfigurator={productConfigurator}
          isMultiSkus={isMultiSkus}
        />
      )}
    </div>
  );
};

export { ProductCard, SkuCard, AuthenticateRequiredMessage };
